import Swiper from "swiper";

//トップページ　キービジュアルスライダー
export function idxFunc() {
  // $('.telPopFunc').on('click', function () {
  //     //$('.popup').addClass('show').fadeIn();
  //     window.confirm("これが確認ダイアログです。");
  // });

  // $('#close').on('click', function () {
  //     $('.popup').fadeOut();
  // });
  console.log("aaa");
}
